export function textShortener(text: string, limit: number) {
  if (!text) {
    return "-"
  }

  if (text.length > limit) {
    return `${text.substr(0, limit)}...`
  } else {
    return text
  }
}

export function createMarkup(html: string) {
  return { __html: html }
}

export function priceFormatter(price: number | string) {
  if (!price) {
    return "Gratis"
  } else if (typeof price === "string") {
    return `Rp ${new Intl.NumberFormat("id-ID").format(parseInt(price, 10))}`
  } else {
    return `Rp ${new Intl.NumberFormat("id-ID").format(price)}`
  }
}

export function priceShortener(price: number) {
  let priceShorten: string

  switch (true) {
    case price < 1000:
      priceShorten = price.toString()
      break
    case price < 1000000:
      priceShorten = `${price / 1000}k`
      break
    default:
      priceShorten = `${price / 1000000}jt`
      break
  }

  return priceShorten
}

export function inboxUserNotificationCounter(rooms: any) {
  let totalUserUnread = 0

  for (let index = 0; index < rooms.length; index++) {
    if (rooms[index].unread_count) {
      totalUserUnread++
    }
  }

  return totalUserUnread
}

export function pad(num: number) {
  let str: string
  if (num === undefined) {
    str = "0"
  } else {
    str = num.toString()
  }
  let newNum
  if (str.length === 1) {
    newNum = str.padStart(2, "0")
  } else {
    newNum = num
  }

  return newNum
}

export function stripHTMLTags(str: string) {
  if (!str) {
    return ""
  }
  return str.replace(/<[^>]*>/g, "")
}
