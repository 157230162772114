import React from "react"
import clsx from "clsx"
import Image from "next/image"

interface AvatarProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  size: number
  src: string
}

export const Avatar: React.FC<AvatarProps> = ({ size = 30, src, ...props }) => {
  if (!src) {
    return <></>
  }

  return (
    <Image
      src={src ?? `https://singlecolorimage.com/get/979797/${size}x${size}`}
      className={clsx("rounded-full object-cover", props.className)}
      placeholder="blur"
      blurDataURL={`https://singlecolorimage.com/get/979797/${size}x${size}`}
      width={size}
      height={size}
      alt={props.alt ?? "avatar-image"}
    />
  )
}

interface ImageCoverProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  size: [width: number, height: number]
  src: string
  rounded?: string
}

export const ImageCover: React.FC<ImageCoverProps> = ({
  size,
  src,
  rounded = "rounded",
  ...props
}) => {
  return (
    <div className={clsx(rounded, props.className)}>
      <Image
        src={
          src?.length !== 0 ? src : `https://singlecolorimage.com/get/979797/${size[0]}x${size[1]}`
        }
        alt={props.alt || ""}
        width={size[0]}
        height={size[1]}
        className={clsx(rounded, "object-cover", props.className)}
        placeholder="blur"
        blurDataURL={`https://singlecolorimage.com/get/979797/${size[0]}x${size[1]}`}
      />
    </div>
  )
}

export const BasicImage: React.FC<React.ImgHTMLAttributes<HTMLImageElement>> = (props) => {
  // @ts-ignore
  return <Image {...props} alt={props.alt || ""}/>
}
