import { JWT_SECRET, TOKEN_COOKIES } from "@const/shared"
import { ErrorResponse, ValidatedResponse, ValidationError } from "@interfaces/response"
import jwt from "jsonwebtoken"
import ky from "ky"
import { HttpMethod } from "ky/distribution/types/options"
import { destroyCookie, parseCookies } from "nookies"

export default class KyMethod {
  private readonly errorMessage: string
  private readonly instance: typeof ky

  constructor() {
    this.errorMessage = "Terjadi kesalahan. Silahkan coba lagi"
    this.instance = ky.extend({
      prefixUrl: process.env.API_ENDPOINT,
    })
  }

  _tokenChecker() {
    const parsedCookies = parseCookies()
    const token = parsedCookies.HSTOKENID

    if (!token) {
      return null
    }

    jwt.verify(token, JWT_SECRET, (err) => {
      if (err) {
        destroyCookie(null, TOKEN_COOKIES)
        return null
      }
    })

    return token
  }

  protected async _getResponse<T>(res: Response): Promise<ValidatedResponse<T>> {
    if (!res.ok) {
      return {
        error: "Terjadi kesalahan. Silahkan coba lagi",
        error_code: "SERVER_ERROR",
      }
    }

    const response = await res.json()

    if (response.error || response.error_code) {
      return {
        error: response.message ?? "Terjadi kesalahan. Silahkan coba lagi",
        error_code: response.error_code ?? "SERVER_ERROR",
        result: response.result,
        error_data: response.error,
      }
    }

    return {
      error: "",
      error_code: "",
      result: response?.result ?? (response as T),
    }
  }

  protected async _getRawResult<T>(res: Response): Promise<T> {
    const data = await res.json()

    return data.result as T
  }

  protected _getResult<T>(data: any): T {
    const result = data.result
    return result as T
  }

  protected async _getErrorObject(error: any): Promise<ErrorResponse> {
    const errResponse = error.response

    if (!errResponse) {
      return {
        message: "Terjadi kesalahan",
        error_code: "SERVER_ERROR",
      }
    }

    const errorData = await errResponse.json()

    if (errorData.error_code) {
      return {
        message: errorData.message,
        error_code: errorData.error_code,
      }
    } else {
      const errors = errorData.errors as ValidationError

      return {
        message: errors.message,
        error_code: errors.validation,
      }
    }
  }

  /**
   * Get method for public API
   */
  protected async _getPublicData(url: string, version?: "v2" | "v3") {
    return this.instance.get((version ?? "v2") + url)
  }

  /**
   * CRUD method for public API
   */
  protected async _crudPublicMethod(
    url: string,
    body: any = {},
    options?: {
      method: HttpMethod
      version: "v2" | "v3"
    }
  ) {
    return this.instance((options?.version ?? "v2") + url, {
      method: options?.method ?? "post",
      json: body,
    })
  }

  /**
   * GET method for public API which have pagination structure data
   */
  protected async _getPaginationData(
    url: string,
    query: any = {},
    options: { version: "v2" | "v3" } = { version: "v2" }
  ) {
    const version = options.version
    return this.instance.get(version + url, {
      searchParams: query,
    })
  }

  /**
   * GET method for protected API
   */
  protected async _getProtectedData(
    url: string,
    options?: {
      version?: "v2" | "v3"
      query?: any
    }
  ) {
    const token = this._tokenChecker()

    if (token) {
      return this.instance.get((options?.version ?? "v2") + url, {
        ...(options?.query && { searchParams: options.query }),
        headers: {
          Authorization: "Bearer " + token,
        },
      })
    } else {
      throw {
        statusCode: 401,
        message: "Sesi login telah habis. Silahkan login ulang",
      }
    }
  }

  /**
   * CRUD method for protected API
   */
  protected async _protectedCrudMethod(
    url: string,
    options?: {
      body?: any
      version?: "v2" | "v3"
      query?: any
      method?: HttpMethod
      bodyType?: "form-data" | "json"
    }
  ) {
    const type = options?.bodyType ?? "json"
    const token = this._tokenChecker()

    if (token) {
      return this.instance((options?.version ?? "v2") + url, {
        ...(options?.query && { searchParams: options.query }),
        headers: {
          Authorization: "Bearer " + token,
        },
        ...(type === "form-data" && { body: options?.body }),
        ...(type === "json" && { json: options?.body ?? {} }),
        method: options?.method ?? "post",
      })
    } else {
      throw {
        statusCode: 401,
        message: "Sesi login telah habis. Silahkan login ulang",
      }
    }
  }

  /**
   * CRUD method for protected API
   */
  protected async _protectedExternalCrudMethod(
    url: string,
    options?: {
      body?: any
      query?: any
      method?: HttpMethod
      bodyType?: "form-data" | "json"
      token: string
    }
  ) {
    const type = options?.bodyType ?? "json"

    if (options?.token) {
      return ky(url, {
        ...(options?.query && { searchParams: options.query }),
        headers: {
          Authorization: "Bearer " + options?.token,
        },
        ...(type === "form-data" && { body: options?.body }),
        ...(type === "json" && { json: options?.body ?? {} }),
        method: options?.method ?? "post",
      })
    } else {
      throw {
        statusCode: 401,
        message: "Sesi login telah habis. Silahkan login ulang",
      }
    }
  }
}
