import dayjs from "dayjs"
import { GetServerSidePropsContext } from "next"
import { destroyCookie, parseCookies } from "nookies"
import { JWT_SECRET } from "@const/shared"
import jwt from "jsonwebtoken"
import { HARISENIN_PUBLIC_LOGO } from "@const/pictures"

export function formatChecker(file: string) {
  const imageFormat = [".png", ".jpeg", ".jpg", ".bmp", ".jfif", ".jif"]
  return imageFormat.some((str) => file.includes(str))
}

export function getTimeDifference(time: Date) {
  let timeStamps
  const a = dayjs(time).format("YYYY-MM-DD")
  const b = dayjs(new Date()).format("YYYY-MM-DD")
  const timeA = dayjs(a)
  const timeB = dayjs(b)

  const difference = timeB.diff(timeA, "days")
  if (difference === 0) {
    timeStamps = dayjs(time).format("HH.mm")
  } else {
    timeStamps = difference + "d"
  }

  return timeStamps
}

export function serverTokenChecker(ctx: GetServerSidePropsContext) {
  const parsedCookies = parseCookies(ctx)
  const token = parsedCookies.HSTOKENID

  if (!token) {
    return null
  }

  jwt.verify(token, JWT_SECRET, (err) => {
    if (err) {
      destroyCookie(ctx, "HSTOKENID", {})
      return null
    }
  })

  return token
}

export function domainChecker(): string {
  const isProduction = process.env.NODE_ENV === "production"

  if (isProduction) {
    return process.env.COOKIES_DOMAIN || process.env.HARISENIN_COOKIES_DOMAIN || ""
  } else {
    return ""
  }
}

export function checkCreditCardType(cc: string, getName?: boolean) {
  const cardNumber = cc.replace(/ /g, "")
  const amex = new RegExp("^3[47][0-9]{13}$")
  const visa = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$")
  const cup1 = new RegExp("^62[0-9]{14}[0-9]*$")
  const cup2 = new RegExp("^81[0-9]{14}[0-9]*$")

  const mastercard = new RegExp("^5[1-5][0-9]{14}$")
  const mastercard2 = new RegExp("^2[2-7][0-9]{14}$")

  const disco1 = new RegExp("^6011[0-9]{12}[0-9]*$")
  const disco2 = new RegExp("^62[24568][0-9]{13}[0-9]*$")
  const disco3 = new RegExp("^6[45][0-9]{14}[0-9]*$")

  const diners = new RegExp("^3[0689][0-9]{12}[0-9]*$")
  const jcb = new RegExp("^35[0-9]{14}[0-9]*$")

  if (visa.test(cardNumber)) {
    return !getName ? `${HARISENIN_PUBLIC_LOGO}/logo_visa.png` : "VISA"
  }
  if (amex.test(cardNumber)) {
    return "AMEX"
  }
  if (mastercard.test(cardNumber) || mastercard2.test(cardNumber)) {
    return !getName ? `${HARISENIN_PUBLIC_LOGO}/logo_mastercard.png` : "MASTERCARD"
  }
  if (disco1.test(cardNumber) || disco2.test(cardNumber) || disco3.test(cardNumber)) {
    return "DISCOVER"
  }
  if (diners.test(cardNumber)) {
    return "DINERS"
  }
  if (jcb.test(cardNumber)) {
    return !getName ? `${HARISENIN_PUBLIC_LOGO}/logo_jcb.png` : "JCB"
  }
  if (cup1.test(cardNumber) || cup2.test(cardNumber)) {
    return "CHINA_UNION_PAY"
  }
  return ""
}

export function youtubeParser(url: string) {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)
  return match && match[7].length == 11 ? match[7] : ""
}
