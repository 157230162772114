import { FaRegStarHalf, FaStar, FaStarHalfAlt } from "react-icons/fa"

export function StarRating({ value }: { value: number }) {
  const rounded = Math.floor(value)
  const element = []

  for (let i = 1; i <= 5; i++) {
    if (i <= rounded) {
      element.push(<FaStar key={i} />)
    } else {
      const reduced = Math.abs(value - i)

      if (reduced % 1) {
        element.push(<FaStarHalfAlt key={i} />)
      } else {
        element.push(<FaRegStarHalf key={i} />)
      }
    }
  }

  return (
    <>
      {element.map((value1) => {
        return value1
      })}
    </>
  )
}
