import { useRouter } from "next/router"

export default function useOpenGraphImage() {
  const router = useRouter()
  const searchParams = router.asPath
  // Open Graph & Twitter images need a full URL including domain
  const fullImageURL = getAbsoluteURL(`api/og/risebinar${searchParams}`)
  return { imageURL: fullImageURL }
}

export const getAbsoluteURL = (path: string) => {
  const baseURL =
    process.env.NODE_ENV === "production"
      ? process.env.HOME_URL || process.env.HARISENIN_HOME_URL
      : "https://www.harisenin.net/risebinar"
  return baseURL + "/risebinar" + "/" + path
}

export const useFullUrl = () => {
  const router = useRouter()
  const baseURL =
    process.env.NODE_ENV === "production"
      ? process.env.HOME_URL || process.env.HARISENIN_HOME_URL
      : "https://www.harisenin.net"
  return baseURL + "/risebinar" + router.asPath
}
