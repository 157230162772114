import { Result } from "./result"
import AuthRepo from "@repositories/auth.repository"
import { destroyCookie, setCookie } from "nookies"
import {
  GoogleLoginBody,
  GoogleLoginResponse,
  LoginManualBody,
  LoginResponse,
  RawTokenData,
  RawUserTraits,
  UserTraits,
} from "@interfaces/auth"
import { deleteTokenCookies, domainChecker, getTokenInfo, setTokenCookies } from "@lib/functions"
import { ErrorResponse } from "@interfaces/response"

export default class AuthServices extends AuthRepo {
  async manualLogin({ email, password }: LoginManualBody) {
    try {
      const res = await this.rawLogin({ user_email: email, user_password: password })

      const response = await this._getResponse<RawTokenData | LoginResponse>(res)

      const { error, result, error_code } = response

      if (error || !result) {
        switch (error_code) {
          case "REQUIRED":
            return Result.fail<RawTokenData>("Terjadi Kesalahan. Silahkan coba lagi")
          case "WRONG_PASSWORD":
            return Result.fail<RawTokenData>("Password yang kamu masukkan salah")
          case "NOT_FOUND":
            return Result.fail<RawTokenData>("Email belum terdaftar")

          default: {
            return Result.fail<RawTokenData>()
          }
        }
      }

      const tokenData = setTokenCookies(result as LoginResponse)

      if (!tokenData) {
        return Result.fail<RawTokenData>(
          "Terjadi Kesalahan. Pastikan form terisi dengan benar dan silahkan coba lagi"
        )
      }

      if (tokenData.data?.status_completed_profile) {
        destroyCookie(null, "HS_TEMP")
      }

      return Result.ok<RawTokenData>(tokenData)
    } catch (e: any) {
      const errorData = await this._getErrorObject(e)
      const errorCode = errorData.error_code

      switch (errorCode) {
        case "NOT_VERIFIED":
          return Result.fail<RawTokenData>(
            "Akun ini belum diaktifkan. Silahkan cek email kamu untuk mengaktifkan akun"
          )
        case "DEACTIVATED":
          return Result.fail<RawTokenData>("Akun ini sudah dinonaktifkan")
        case "SUSPENDED":
          return Result.fail<RawTokenData>("Akun ini telah ditangguhkan")

        default: {
          return Result.fail<RawTokenData>("Terjadi kesalahan. Silahkan coba lagi")
        }
      }
    }
  }

  async googleLogin({ email, fullName, googleId }: GoogleLoginBody) {
    try {
      const res = await this.rawGoogleLogin({ userFullname: fullName, googleId, email })

      const response = await this._getResponse<GoogleLoginResponse | LoginResponse>(res)

      const { result } = response

      if (!result) {
        return Result.fail<ErrorResponse>("Terjadi Kesalahan. Silahkan coba lagi")
      }

      const tokenData = setTokenCookies(result as LoginResponse)

      if (tokenData) {
        destroyCookie(null, "HS_TEMP")
        return Result.ok<ErrorResponse>({ message: "Login Success", error_code: "SUCCESS" })
      }

      const loginResult = result as GoogleLoginResponse

      if (loginResult.new_user) {
        const resultData = { ...loginResult, user_email: email }

        setCookie(null, "HS_TEMP", JSON.stringify(resultData), {
          path: "/",
          domain: domainChecker(),
        })

        return Result.ok<ErrorResponse>({
          message: "Isi password dan biodata lengkap",
          error_code: "NEW_USER",
        })
      }

      if (loginResult.status_completed_profile) {
        return Result.fail<ErrorResponse>("Terjadi Kesalahan. Silahkan coba lagi")
      }

      setCookie(null, "HS_TEMP", JSON.stringify(loginResult), {
        path: "/",
        domain: domainChecker(),
      })
      return Result.ok<ErrorResponse>({
        message: "Isi biodata lengkap",
        error_code: "NOT_COMPLETED",
      })
    } catch (e: any) {
      const errorData = await this._getErrorObject(e)
      const errorCode = errorData.error_code

      switch (errorCode) {
        case "ACCOUNT_HAS_BEEN_DEACTIVATED":
          return Result.fail<ErrorResponse>("Akun ini sudah dinonaktifkan")
        case "USER_ACCOUNT_SUSPENDED":
          return Result.fail<ErrorResponse>("Akun ini telah ditangguhkan")
        case "USER_ALREADY_EXISTS":
          return Result.fail<ErrorResponse>(
            "Email sudah terdaftar, silahkan cek email dan verifikasi akun kamu."
          )

        default: {
          return Result.fail<ErrorResponse>("Terjadi Kesalahan. Silahkan coba lagi")
        }
      }
    }
  }

  async resendVerificationEmail(email: string) {
    try {
      const res = await this.rawResendVerification(email)
      const response = await this._getResponse<any>(res)
      const { error, result, error_code } = response

      if (error || error_code || !result) {
        return Result.fail<any>()
      }
      return Result.ok<{ message: string }>({ message: "Success" })
    } catch (e: any) {
      return Result.fail<any>()
    }
  }

  async refreshToken() {
    try {
      const { refreshToken } = getTokenInfo()

      const res = await this.rawRefreshToken({ refresh_token: refreshToken })
      const response = await this._getResponse<any>(res)
      const { result } = response

      if (result) {
        setTokenCookies(result)
      }
    } catch (e: any) {
      return Result.fail()
    }
  }

  async logout() {
    deleteTokenCookies()

    try {
      await this.rawLogout()
    } catch (e: any) {
      return Result.fail<any>()
    }
  }

  async getUserData() {
    try {
      const res = await this.rawGetUserData()

      if (!res.ok) {
        return Result.fail<UserTraits>("Terjadi Kesalahan. Silahkan coba lagi")
      }

      const result = await this._getRawResult<RawUserTraits>(res)

      return Result.ok<UserTraits>(result.traits)
    } catch (error) {
      return Result.fail<UserTraits>("Terjadi Kesalahan. Silahkan coba lagi")
    }
  }
}
