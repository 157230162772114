import { useGoogleOneTapLogin } from "@hooks/useOneTapGoogleLogin"
import { AnalyticsProvider } from "@lib/context/AnalyticsContext"
import { ComponentProvider } from "@lib/context/ComponentContext"
import * as gtag from "@lib/functions"
import dayjs from "dayjs"
import "dayjs/locale/id"
import relativeTime from "dayjs/plugin/relativeTime"
import { Router, useRouter } from "next/router"
import nProgress from "nprogress"
import "nprogress/nprogress.css"
import React, { useEffect } from "react"
import "react-multi-carousel/lib/styles.css"
import "react-phone-number-input/style.css"
import "regenerator-runtime/runtime"
import "../styles/style.scss"
import Error from "./_error"

// const FloatingWhatsApp = dynamic(() => import("@harisenin/react-floating-whatsapp"), { ssr: false })

const isProduction = process.env.NODE_ENV === "production"

// @ts-ignore
const App = ({ Component, pageProps }) => {
  Router.events.on("routeChangeStart", () => {
    nProgress.start()
  })
  Router.events.on("routeChangeComplete", () => {
    nProgress.done()
    // smoothScroll();
  })
  Router.events.on("routeChangeError", () => {
    nProgress.done()
  })

  const router = useRouter()
  dayjs.extend(relativeTime)
  dayjs.locale("id")

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      /* invoke analytics function only for production */
      if (isProduction) gtag.pageview(url)
    }
    router.events.on("routeChangeComplete", handleRouteChange)
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router.events])

  useGoogleOneTapLogin()

  if (pageProps.error) {
    return <Error />
  }

  return (
    // @ts-ignore
    // <ErrorBoundary FallbackComponent={Error}>
    <AnalyticsProvider>
      <ComponentProvider>
        {/*// @ts-ignore*/}
        {/* <FloatingWhatsApp
        phoneNumber="+6281312117711"
        accountName="Customer Services"
        allowClickAway
        notification
        notificationDelay={30000}
      /> */}
        {/*<PromoPopupProvider>*/}
        <Component {...pageProps} />
        {/*</PromoPopupProvider>*/}
      </ComponentProvider>
    </AnalyticsProvider>

    // </ErrorBoundary>
  )
}

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function getServerSideProps({ Component, router, ctx }) {
  let pageProps = {}

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx)
  }

  return { pageProps }
}

export default App
