import nookies, { destroyCookie, setCookie } from "nookies"
import jwt from "jsonwebtoken"
import { LoginResponse, RawTokenData } from "@interfaces/auth"
import { JWT_SECRET, REFRESH_TOKEN_COOKIES, TOKEN_COOKIES } from "@const/shared"
import { domainChecker } from "./checker"

export function setTokenCookies(tokenResponse: LoginResponse) {
  setCookie(null, TOKEN_COOKIES, tokenResponse.token, {
    maxAge: 3 * 60 * 60,
    path: "/",
    domain: domainChecker(),
  })

  setCookie(null, REFRESH_TOKEN_COOKIES, tokenResponse.refreshToken, {
    maxAge: 3 * 60 * 60,
    path: "/",
    domain: domainChecker(),
  })

  const token = tokenResponse.token
  let tokenData: RawTokenData | undefined

  if (token) {
    jwt.verify(token, JWT_SECRET, {}, async (err, decoded) => {
      if (err) {
        deleteTokenCookies()
        return ""
      } else {
        tokenData = decoded as RawTokenData
      }
    })
  }

  return tokenData
}

export function deleteTokenCookies() {
  const isProduction = process.env.NODE_ENV === "production"

  destroyCookie(null, TOKEN_COOKIES, {
    domain: isProduction
      ? process.env.COOKIES_DOMAIN || process.env.HARISENIN_COOKIES_DOMAIN || ""
      : "",
    path: "/",
  })

  destroyCookie(null, REFRESH_TOKEN_COOKIES, {
    domain: isProduction
      ? process.env.COOKIES_DOMAIN || process.env.HARISENIN_COOKIES_DOMAIN || ""
      : "",
    path: "/",
  })
}

export function getTokenInfo(): LoginResponse {
  const cookies = nookies.get({})

  const token = cookies[TOKEN_COOKIES]
  const refreshToken = cookies[REFRESH_TOKEN_COOKIES]

  return { token, refreshToken }
}
