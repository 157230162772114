import Head from "next/head"
import useOpenGraphImage, { useFullUrl } from "../functions/graph-image"

export interface HelmetProps {
  title?: string
  description?: string
  image?: string
  urlSlug?: string
  isIndexed?: boolean
}

const Helmet = ({ title, description, isIndexed }: HelmetProps) => {
  const { imageURL } = useOpenGraphImage()
  const fullUrl = useFullUrl()

  return (
    <Head>
      <title>{title || "One stop skill development platform - harisenin.com"}</title>
      <meta
        name="description"
        content={
          description ||
          `Harisenin.com adalah Online Education Platform yang menawarkan education-to-employment program untuk profesi yang paling banyak dibutuhkan saat ini`
        }
      />
      <meta name="keywords" content="bootcamp, jobs, freelance, digital marketing" />
      <meta name="robots" content="index, follow" />{" "}
      <meta name="googlebot" content={isIndexed ? "index, follow" : "noindex"} />
      <meta name="language" content="Indonesian" />
      <meta name="author" content="harisenin.com" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={fullUrl} />
      <meta
        property="og:title"
        content={title || "One stop skill development platform - Harisenin.com"}
      />
      <meta
        property="og:description"
        content={
          description ||
          "Harisenin.com adalah Online Education Platform yang menawarkan education-to-employment program untuk profesi yang paling banyak dibutuhkan saat ini"
        }
      />
      <meta property="og:image" content={imageURL} />
      <meta property="og:site_name" content="Harisenin, PT Karya Kaya Bahagia" />
      <meta
        property="og:image:alt"
        content={
          title ||
          "Pelajari keahlian dan rahasia sukses untuk mendapatkan the most wanted job in the world"
        }
      />
      <meta
        property="twitter:title"
        content={title || "One stop skill development platform - harisenin.com"}
      />
      <meta
        property="twitter:description"
        content={
          description ||
          "Harisenin.com adalah Online Education Platform yang menawarkan education-to-employment program untuk profesi yang paling banyak dibutuhkan saat ini"
        }
      />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={fullUrl} />
      <meta property="twitter:image" content={imageURL} />
      <meta
        property="twitter:image:alt"
        content={title || "One stop skill development platform - harisenin.com"}
      />
      <meta property="twitter:site" content="@harisenincom" />
      {/*facebook optional*/}
      <meta property="fb:app_id" content="970446600184538" />
      <meta property="ia:markup_url" content={fullUrl} />
      <meta property="ia:markup_url_dev" content={fullUrl} />
      <meta property="ia:rules_url" content={fullUrl} />
      <meta property="ia:rules_url_dev" content={fullUrl} />
      <link rel="canonical" href={fullUrl} />
    </Head>
  )
}

export default Helmet
