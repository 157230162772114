export const HARISENIN_PUBLIC_S3 = `${
  process.env.CDN_URL || process.env.HARISENIN_CDN_URL
}/public/assets`

export const HARISENIN_PUBLIC_LOGO = `${HARISENIN_PUBLIC_S3}/logos`

export const HARISENIN_PUBLIC_ICON = `${HARISENIN_PUBLIC_S3}/icon`

export const HARISENIN_PUBLIC_PAGE_ASSETS = `${HARISENIN_PUBLIC_S3}/page-assets`

export const HARISENIN_LOGO = `${HARISENIN_PUBLIC_LOGO}/logo_harisenin.svg`

export const NO_INBOX_SMALL = `${HARISENIN_PUBLIC_S3}/placeholders/placeholder_no-inbox-small.png`

export const NO_GAWEAN = `${HARISENIN_PUBLIC_S3}/placeholders/placeholder_no-product.png`

export const NO_DATA = `${HARISENIN_PUBLIC_PAGE_ASSETS}/risebinar_no-data.png`

export const HARISENIN_LOGO_WHITE = `${HARISENIN_PUBLIC_LOGO}/logo_harisenin-white-new.png`
