export const JWT_SECRET = "7kDeBYvzbD46QaJCzWpYtMxme04fzWZDZCGOP3Jp1bhlhIzw251e4xozjpUtZi86"

export const GTMId = process.env.GTM_ID

export const TOKEN_COOKIES = "HSTOKENID"
export const REFRESH_TOKEN_COOKIES = "HSRTKID"

export const GADSENSE = process.env.GOOGLE_ADSENSE_ID || "" // This is your GA Tracking ID


export const FOOTER = [
  {
    title: "layanan",
    links: [
      {
        link: "/school/bootcamp",
        label: "Bootcamp",
      },
      {
        link: "/school/proclass",
        label: "ProClass",
      },
      // {
      //   link: "/prakerja",
      //   label: "Prakerja",
      // },
      {
        link: "/learning",
        label: "Video Course",
      },
      {
        link: "/risebinar",
        label: "Event & Workshop",
      },
      {
        link: "/hire-graduates",
        label: "Hire Our Graduates",
      },
    ],
  },
  {
    title: "tentang",
    links: [
      {
        link: "/tentang-kami",
        label: "Tentang Kami",
      },
      {
        link: "/alumni",
        label: "Alumni",
      },
      {
        link: "https://karir.harisenin.com",
        label: "Karir",
      },
    ],
  },
  {
    title: "bantuan",
    links: [
      {
        link: "/faq",
        label: "FAQ",
      },
      {
        link: "/kebijakan-privasi",
        label: "Kebijakan Kerahasiaan Data",
      },
      {
        link: "/syarat-ketentuan",
        label: "Ketentuan Layanan",
      },
    ],
  },
  {
    title: "komunitas",
    links: [
      {
        link: "https://tipssukses.harisenin.com/",
        label: "Blog",
      },
      {
        link: "https://www.youtube.com/channel/UCjzbRwULkasMnjTinnzngwg",
        label: "Youtube",
      },
    ],
  },
]
